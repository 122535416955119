import React, { useState, useEffect } from 'react';

const FlashingAd = () => {
  const [flashState, setIsFlashing] = useState(0);

  useEffect(() => {
    const flashInterval = setInterval(() => {
      setIsFlashing(prev => (prev + 1) % 4);
    }, 2500);

    return () => clearInterval(flashInterval);
  }, []);

  const getFlashClass = () => {
    switch(flashState) {
      case 1:
        return 'flash-red';
      case 2:
        return 'flash-blue';
      case 3:
        return 'flash-pink';
      default:
        return '';
    }
  };

  return (
    <div>
      <div className="ad-container">
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        {/* 
        <div className={`flashing-ad ${isFlashing ? 'flash' : ''}`}>
        */
        }
        <div className={`flashing-ad ${getFlashClass()}`}>
          <a href="https://app.squareup.com/gift/ML6SGAZMY5MN0/order">Give the Perfect Gift!</a>
        </div>
      </div>
      <span className="ad-caption">Take 10% OFF with code: HOLIDAY10</span>
    </div> 
  );
};

export default FlashingAd;
