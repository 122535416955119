import React, { useState, useEffect } from 'react';
import Hero from './Hero/Hero'
import Navbar from './Navbar/Navbar'
import Services from './Services/Services'
//import Hours from './Hours/Hours'

import './Home.css'

const Home = () => {
    useEffect(() => {
        const createSnowflakes = (count) => {
            const container = document.createElement('div');
            container.className = 'snow-container';
            const snowContent = ['❄', '❅', '❆'];
            
            for (let i = 0; i < count; i++) {
                const snowflake = document.createElement('div');
                snowflake.className = 'snowflake';
                snowflake.style.left = `${Math.random() * 100}%`;
                snowflake.style.animationDelay = `${Math.random() * 10}s`;
                snowflake.style.opacity = Math.random();
                snowflake.innerHTML = snowContent[Math.floor(Math.random() * snowContent.length)];
                container.appendChild(snowflake);
            }
            
            document.body.appendChild(container);
        };

        createSnowflakes(30);

        // Cleanup function
        return () => {
            const container = document.querySelector('.snow-container');
            if (container) {
                container.remove();
            }
        };
    }, []);
    return (
        <div>
            <Navbar/>
            <Hero/>
            <Services/>
        </div>
    )
}

export default Home
