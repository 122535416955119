import React, {useState, useEffect} from 'react'
import './SideNav.css'
import { Link } from 'react-router-dom';
import {checkCookie, getCookie, deleteCookie} from '../../cookies'
import {time, fullTime} from '../../time'

const SideNav = () => {

    //const [day, setDay] = useState('sun')
    const [workTime, setWorkTime] = useState('')
    const [name, setName] = useState('user')


    useEffect(()=>{
        let loginAndReg = document.querySelector('.side-log-reg')
        let helloUser = document.querySelector('.side-logout')
        let controlPanel = document.querySelector('.side-cp')
        let userProfile = document.querySelector('.side-up')

        //let {day} = time()
        //console.log(day)
        //dayOfWeek(day)
        if(getCookie('name'))
            setName(getCookie('name'))

        if(getCookie('admin') === 'true'){
            controlPanel.style.display = 'block'
            userProfile.style.display = 'none'

        }
        else{
            controlPanel.style.display = 'none'
            userProfile.style.display = 'block'
        }
        if(checkCookie('status')){
            console.log('user logged')
            helloUser.style.display = 'flex'
            loginAndReg.style.display = 'none'
        }else{
            console.log('user not logged')
            helloUser.style.display = 'none'
            loginAndReg.style.display = 'flex'
            userProfile.style.display = 'none'

        }
    },[])

    const logout = () =>{
        console.log('user logout')
        deleteCookie('name')
        deleteCookie('id')
        deleteCookie('status')
        deleteCookie('admin')
        window.location.replace('/')
    }

    return (
        <div className='side-navbar'>
            <ul className='side-navbar-ul'>
                    <li className=''>
                        <a 
                            className='side-log-reg-link' 
                            href="https://nail-labs.square.site/"
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            More Inspo
                        </a>
                    </li>
                    <li className=''>
                        <a 
                            className='side-log-reg-link' 
                            href='https://maps.app.goo.gl/Y7KAXQVUN6dYDRzV9'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Map Location
                        </a>
                    </li>
                    <li className=''>
                        <a 
                            className='side-log-reg-link' 
                            href='https://g.page/r/CZd1_yQIBuYcEBM/review'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Review Us
                        </a>
                    </li>
                    <li id='control-panel' className='nav-items'>
                        <Link className='links side-cp' to='/admin'>
                            Control Panel
                        </Link>
                    </li>
                    <li id='user-profile' className='nav-items'>
                        <Link className='links side-up' to='/profile'>
                            User Profile
                        </Link>
                    </li>
                </ul>
                <div className='side-logout'>       
                    <button className='side-btn' onClick={logout}>Logout</button>
                </div>
                <div className='side-log-reg'>
                    <a 
                        className='side-log-reg-link' 
                        href="sms:+13018926640">
                        Text
                    </a>
                    <span className='side-separator'></span>
                    <a 
                        className='side-log-reg-link' 
                        href="tel:+13016032909">
                        Call
                    </a>
                </div>
        </div>
    )
}

export default SideNav
