import React from 'react'
import './Hero.css'
import { Link } from 'react-router-dom'
 

const Hero = () => {
    return (
        <div className='hero' id='hero-navigate'>
            <div className='hero-text'>
                <h1 className='hero-h1'>Welcome to The Nail Lab</h1>
                <div className='hero-para-div'>
                    <p className='hero-para'>
                        Your Sanctuary for Exquisite Nail Care and Wellness.
                        We are dedicated to providing you with top-notch 
                        nail care and a relaxing and luxurious experience.
                    </p>
                </div>
                <Link to='/appointment'>
                    <button className='hero-btn'>Book Now</button>
                </Link>
            </div>
            {/* Holiday effects */}
            <div className="snow-ground"></div>
            <div className="snow-pile"></div>
            <div className="snow-spots">
                <div className="snow-spot"></div>
                <div className="snow-spot"></div>
                <div className="snow-spot"></div>
                <div className="snow-spot"></div>
                <div className="snow-spot"></div>
                <div className="snow-spot"></div>
            </div>
            <div 
                className="snowman-container"
                onClick={() => window.location.href = 'https://nail-labs.square.site/'}
                role="button"
                tabIndex={0}
                aria-label="Click snowman for inspo!"
            >
                <div className="snowman">
                    <div className="head">
                        <div className="eye left"></div>
                        <div className="eye right"></div>
                        <div className="carrot"></div>
                    </div>
                    <div className="body">
                        <div className="button"></div>
                        <div className="button"></div>
                        <div className="button"></div>
                    </div>
                    <div className="base"></div>
                    <div className="arms left-arm"></div>
                    <div className="arms right-arm"></div>
                </div>
            </div>
        </div>
    )
}

export default Hero
